
<template>
  <div>
    <el-card>
      <vab-query-form>
        <vab-query-form-left-panel :span="24">
          <el-button size="small" style="width: 100px" @click="goBack()">
            返回
          </el-button>
        </vab-query-form-left-panel>
      </vab-query-form>
      <div class="demo-drawer__content">
        <el-form ref="formRef" :model="form" :rules="rules">
          <el-form-item :label="meetingType == 'EXHIBITION' ? '展品名称' : '产品名称'"   prop="exhibitName">
            <el-input v-model="form.exhibitName" autocomplete="off" />
          </el-form-item>
          <el-form-item label="类型" prop="categoryIdsStr">
            <el-select
              v-model="form.categoryIdsStr"
              placeholder="请选择类型"
            >
              <el-option
                v-for="(item, index) in typeList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="属性" prop="attributeIds">
            <el-select
              v-model="form.attributeIds"
              placeholder="请选择属性"
              multiple
            >
              <el-option
                v-for="(item, index) in attrList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>

          <!-- 主办字段 -->
          <template v-if="industryType == 'HOST'">
            <el-form-item label="行业" prop="industry">
              <el-select v-model="form.industry" placeholder="请选择行业">
                <el-option
                  v-for="(item, index) in industryList"
                  :key="item.name"
                  :label="item.name"
                  :value="item.name"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="地区" prop="countryCode">
              <el-select v-model="form.countryCode" placeholder="请选择地区">
                <el-option
                  v-for="(item, index) in counrtyList"
                  :key="item.countryCode"
                  :label="item.country"
                  :value="item.countryCode"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="展会开始时间" prop="eventStartTime">
              <el-date-picker
                v-model="form.eventStartTime"
                type="date"
                placeholder="选择展会开始时间"
              />
            </el-form-item>
            <el-form-item label="展会结束时间	" prop="eventEndTime">
              <el-date-picker
                v-model="form.eventEndTime"
                type="date"
                placeholder="选择展会结束时间	"
              />
            </el-form-item>
            <!-- <el-form-item
              label="省市选择"
              prop="areaList"
              v-if="form.country == 'CN'"
            >
              <el-cascader
                :props="optionsLevel"
                v-model="form.areaList"
                style="width: 250px"
              />
            </el-form-item> -->
          </template>

          <!-- 场馆字段 -->
          <template v-if="industryType == 'VENUES'">
            <el-form-item label="活动类型" prop="activityTypeCode">
              <el-select
                v-model="form.activityTypeCode"
                placeholder="请选择活动类型"
                multiple
              >
                <el-option
                  v-for="(item, index) in activeTypeList"
                  :key="item.code"
                  :label="item.name"
                  :value="item.code"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="人数规模" prop="peopleScaleCode">
              <el-select
                multiple
                v-model="form.peopleScaleCode"
                placeholder="请选择人数规模"
              >
                <el-option
                  v-for="(item, index) in peopleNumList"
                  :key="item.code"
                  :label="item.name"
                  :value="item.code"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="空间面积" prop="venuesAreaCode">
              <el-select
                multiple
                v-model="form.venuesAreaCode"
                placeholder="请选择空间面积"
              >
                <el-option
                  v-for="(item, index) in squareList"
                  :key="item.code"
                  :label="item.name"
                  :value="item.code"
                ></el-option>
              </el-select>
            </el-form-item>
          </template>

          <!-- 招聘字段 -->
          <template v-if="industryType == 'WORK'">
            <el-form-item label="工作地点" prop="workAddressCode">
              <el-select
                v-model="form.workAddressCode"
                placeholder="请选择工作地点"
                multiple
              >
                <el-option
                  v-for="(item, index) in workAddress"
                  :key="item.code"
                  :label="item.name"
                  :value="item.code"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="岗位类型" prop="workTypeCode">
              <el-select
                multiple
                v-model="form.workTypeCode"
                placeholder="请选择岗位类型"
              >
                <el-option
                  v-for="(item, index) in workType"
                  :key="item.code"
                  :label="item.name"
                  :value="item.code"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="价格类型" prop="workPriceCode">
              <el-select
                multiple
                v-model="form.workPriceCode"
                placeholder="请选择价格类型"
              >
                <el-option
                  v-for="(item, index) in workPrice"
                  :key="item.code"
                  :label="item.name"
                  :value="item.code"
                ></el-option>
              </el-select>
            </el-form-item>
          </template>

          <el-form-item :label="meetingType == 'EXHIBITION' ? '展品编码' : '产品编码'"  prop="productInnerCode">
            <el-input v-model="form.productInnerCode" autocomplete="off" />
          </el-form-item>

          <el-form-item :label="meetingType == 'EXHIBITION' ? '展品附件' : '产品附件'" >
            <el-upload
              accept=".pptx, .pdf,.docx,.txt,.md,.csv"
              v-model:file-list="fileList"
              class="upload-demo"
              action="#"
              :multiple="true"
              :http-request="handleChangeUpLoadFile"
              :on-remove="handleRemoveFile"
            >
              <el-button type="primary">附件上传</el-button>
              <template #tip>
                <div class="el-upload__tip"></div>
              </template>
            </el-upload>
          </el-form-item>

          <el-form-item :label="meetingType == 'EXHIBITION' ? '展品主K' : '产品主K'"  prop="cover">
            <div class="upLoadImg">
              <wmt-image :src="form.cover" @deleteImg="deleteCover"></wmt-image>
              <div>
                <el-button @click="upLoadCover">上传图片</el-button>
                <p>建议上传比例为5：4，小于10MB的图片</p>
              </div>
            </div>
          </el-form-item>

          <el-form-item :label="meetingType == 'EXHIBITION' ? '展品图片' : '产品图片'" prop="banner">
            <div class="upLoadImg">
              <draggable
                class="draggable"
                group="model"
                ghostClass="ghost"
                :list="form.banner"
                item-key="index"
              >
                <template #item="{ element }">
                  <wmt-image
                    :src="element.fileUrl"
                    @deleteImg="deleteBannar"
                  ></wmt-image>
                </template>
              </draggable>
              <div>
                <el-upload
                  accept=".png,.jpeg,.jpg,.mp4,.mov,.avi"
                  action="#"
                  class="upload-demo"
                  :multiple="true"
                  :http-request="upBannar"
                  :show-file-list="false"
                >
                  <el-button>上传多张图片/视频</el-button>
                  <el-button @click.stop="upLoadProduct">
                    上传单张图片（可裁剪）
                  </el-button>
                  <div></div>
                </el-upload>
                <p>建议上传尺寸为1:1，小于200MB的图片或视频，不超过10个。</p>
              </div>
            </div>
          </el-form-item>

          <!-- 联系人信息 -->
          <el-form-item label="新增联系人">
            <!-- <el-input v-model="form.contact" autocomplete="off" /> -->
            <el-button type="primary" @click="addContact">添加联系人</el-button>
            <div
              class="userInfoBox"
              v-for="(hostInfo, index) in contactInfo"
              :key="index"
            >
              <el-avatar :size="size" :src="hostInfo.avatar" />
              <div class="detailInfo">
                <div>{{ hostInfo.name }}</div>
                <div>{{ hostInfo.company }} {{ hostInfo.position }}</div>
                <!-- <div>邮箱:{{ hostInfo.email }}</div> -->
              </div>

              <div class="inputBox">
                <el-form-item label="备注">
                  <el-input
                    v-model="hostInfo.dutyDescription"
                    autocomplete="off"
                    placeholder="请输入备注"
                    maxlength="50"
                    @blur="upDateContactInfoFunc(hostInfo)"
                  />
                </el-form-item>
              </div>

              <el-icon
                @click="deleteUserInfo(index)"
                style="
                  font-size: 30px;
                  margin-left: 60px;
                  margin-top: 20px;
                  color: red;
                "
              >
                <Delete />
              </el-icon>
            </div>
          </el-form-item>

          <!-- <el-form-item label="排序" prop="selfSort">
            <el-input v-model="form.selfSort" autocomplete="off" />
          </el-form-item> -->

          <el-form-item label="详情介绍">
            <Toolbar
              style="border-bottom: 1px solid #ccc"
              :editor="editorRef"
              :defaultConfig="toolbarConfig"
              :mode="mode"
            />
            <Editor
              style="height: 500px; overflow-y: auto"
              v-model="form.detailsToc"
              :defaultConfig="editorConfig"
              :mode="mode"
              @onCreated="handleCreated"
            />
          </el-form-item>

          <el-form-item align="center">
            <!-- <el-button @click="handleClose">取消</el-button> -->
            <el-button
              size="large"
              style="width: 200px"
              type="primary"
              @click="submitInfo()"
            >
              提交
            </el-button>
            <!-- <el-button
              size="large"
              style="width: 200px"
              type="primary"
              @click="goBack()"
            >
              返回
            </el-button> -->
          </el-form-item>
        </el-form>
      </div>
    </el-card>
    <vue-cropper
      ref="coverCropperRef"
      @imgInfo="coverInfo"
      :title="'展商主K'"
      :option="mainKOption"
    />
    <vue-cropper
      :upLoadAttchments="true"
      ref="productCropperRef"
      @imgInfo="productInfo"
      :title="'展品图片'"
      :option="productOption"
    />
    <add-user
      ref="editUserRef"
      :exhibitorCode="form.exhibitorCode"
      @addUserInfo="addUserInfo"
    ></add-user>
  </div>
</template>

<script>
  import {
    defineComponent,
    getCurrentInstance,
    onActivated,
    onMounted,
    reactive,
    toRefs,
    defineAsyncComponent,
    shallowRef,
    onBeforeUnmount,
  } from 'vue'
  import { upLoadImg, upLoadAttchments } from '@/api/active'
  import {
    addBrandProduct,
    getBrandProductDetail,
    editBrandProduct,
    editBrandProductSort,
    queryProductAttribute,
    addBatchContactInfo,
    updateContactInfo,
    queryContactInfoByType,
    generateNotRealCode,
    countryQueryInfo,
    queryProductIndustry,
    queryExpansionTypeList,
  } from '@/api/brand'
  import VabQuill from '@/extra/VabQuill'
  import VabUpload from '@/extra/VabUpload'
  import VueCropper from '@/extra/VueCropper'
  import { Picture } from '@element-plus/icons'
  import wmtImage from '@/components/wmt-image'
  import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
  import '@wangeditor/editor/dist/css/style.css'
  import { useRoute } from 'vue-router'
  import { getProvinceInfo, getCityInfo, getAreaInfo } from '@/api/shop'
  import { parseTime } from '@/utils/index'
  export default defineComponent({
    name: 'ArticleEdit',
    components: {
      VabQuill,
      VabUpload,
      VueCropper,
      wmtImage,
      Draggable: defineAsyncComponent(() => import('vuedraggable')),
      Editor,
      Toolbar,
      addUser: defineAsyncComponent(() => import('./components/addContact')),
      // VueUeditorWrap,
    },
    setup(props, { emit }) {
      const { proxy } = getCurrentInstance()

      const route = useRoute()
      // 初始化编辑器
      const editorRef = shallowRef()

      /**
       * @description: cover主k 自定义校验
       * @return {*}
       */
      const validateCover = (rule, value, callback) => {
        if (state.form.cover === '') {
          callback(new Error('请上传展商主K'))
        } else {
          callback()
        }
      }

      const validateBannar = (rule, value, callback) => {
        if (state.form.banner.length > 0 && state.form.banner[0] == '') {
          callback(new Error('请上传主页轮播图'))
        } else {
          callback()
        }
      }
      const state = reactive({
        dialogVisible: false,
        articleVisible: false,
        coverCropperRef: null,
        productCropperRef: null,
        editUserRef: null,
        mainKOption: {
          outputSize: 1, // 裁剪生成图片的质量
          outputType: 'jpeg', // 裁剪生成图片的格式 jpeg, png, webp
          info: true, // 裁剪框的大小信息
          canScale: true, // 图片是否允许滚轮缩放
          autoCrop: true, // 是否默认生成截图框
          autoCropWidth: '500px', // 默认生成截图框宽度
          autoCropHeight: '400px', // 默认生成截图框高度
          fixedBox: true, // 固定截图框大小 不允许改变
          fixed: true, // 是否开启截图框宽高固定比例，这个如果设置为true，截图框会是固定比例缩放的，如果设置为false，则截图框的狂宽高比例就不固定了
          fixedNumber: [5, 4], // 截图框的宽高比例 [ 宽度 , 高度 ]
          canMove: true, // 上传图片是否可以移动
          canMoveBox: true, // 截图框能否拖动
          original: false, // 上传图片按照原始比例渲染
          centerBox: false, // 截图框是否被限制在图片里面
          infoTrue: true, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
          full: true, // 是否输出原图比例的截图
          enlarge: '1', // 图片根据截图框输出比例倍数
          mode: '100%', // 图片默认渲染方式 contain , cover, 100px, 100% auto
        },
        productOption: {
          outputSize: 3, // 裁剪生成图片的质量
          outputType: 'png', // 裁剪生成图片的格式 jpeg, png, webp
          info: true, // 裁剪框的大小信息
          canScale: true, // 图片是否允许滚轮缩放
          autoCrop: true, // 是否默认生成截图框
          autoCropWidth: '500px', // 默认生成截图框宽度
          autoCropHeight: '500px', // 默认生成截图框高度
          fixedBox: true, // 固定截图框大小 不允许改变
          fixed: true, // 是否开启截图框宽高固定比例，这个如果设置为true，截图框会是固定比例缩放的，如果设置为false，则截图框的狂宽高比例就不固定了
          fixedNumber: [1, 1], // 截图框的宽高比例 [ 宽度 , 高度 ]
          canMove: true, // 上传图片是否可以移动
          canMoveBox: true, // 截图框能否拖动
          original: false, // 上传图片按照原始比例渲染
          centerBox: false, // 截图框是否被限制在图片里面
          infoTrue: true, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
          full: true, // 是否输出原图比例的截图
          enlarge: '2', // 图片根据截图框输出比例倍数
          mode: '100%', // 图片默认渲染方式 contain , cover, 100px, 100% auto
        },
        formRef: null,
        vabUploadRef: null,
        form: {
          logo: '',
          cover: '',
          banner: [''],
          details: '',
          exhibitorCode: route.query.exhibitorCode,
          exhibitCategory: '',
          exhibitName: '',
          categoryIdsStr: '',
          categoryIds: '',
          attributeIds: [],
          productAttachmentIds: [],
        },
        rules: {
          exhibitName: [
            { required: true, trigger: 'blur', message: '请输入展品名称' },
          ],
          // productInnerCode: [
          //   { required: true, trigger: 'blur', message: '请输入展品编码' },
          // ],
          exhibitCategory: [
            { required: true, trigger: 'blur', message: '请输入品牌简称' },
          ],
          cover: [
            { required: true, trigger: 'change', validator: validateCover },
          ],
          banner: [
            { required: true, trigger: 'change', validator: validateBannar },
          ],
          industry: [
            { required: true, trigger: 'change', message: '请选择展品行业' },
          ],
          countryCode: [
            { required: true, trigger: 'change', message: '请选择地区' },
          ],
          eventStartTime: [
            { required: true, trigger: 'change', message: '请选择开始时间' },
          ],
          eventEndTime: [
            { required: true, trigger: 'change', message: '请选择结束时间' },
          ],
          activityTypeCode: [
            { required: true, trigger: 'change', message: '请选择活动类型' },
          ],
          peopleScaleCode: [
            { required: true, trigger: 'change', message: '请选择人数规模' },
          ],
          venuesAreaCode: [
            { required: true, trigger: 'change', message: '请选择空间面积' },
          ],
          workAddressCode: [
            { required: true, trigger: 'change', message: '请选择工作地点' },
          ],
          workTypeCode: [
            { required: true, trigger: 'change', message: '请选择岗位类型' },
          ],
          workPriceCode: [
            { required: true, trigger: 'change', message: '请选择价格类型' },
          ],
          // categoryIds: [
          //   { required: true, trigger: 'change', message: '请选择展品类型' },
          // ],
        },
        status: 'new',
        queryType: {
          pageNum: 1,
          pageSize: 999,
          enterpriseCode: route.query.enterpriseCode,
          exhibitorCode: route.query.exhibitorCode,
          enterpriseType: route.query.enterpriseCode
            ? 'ENTERPRISE'
            : 'EXHIBITOR',
          type: 'TYPE',
        },
        queryAttr: {
          pageNum: 1,
          pageSize: 999,
          enterpriseCode: route.query.enterpriseCode,
          exhibitorCode: route.query.exhibitorCode,
          enterpriseType: route.query.enterpriseCode
            ? 'ENTERPRISE'
            : 'EXHIBITOR',
          type: 'ATTRIBUTE',
        },
        attrList: [],
        typeList: [],
        contactInfo: [],
        code: '',
        notRealCode: '',
        fileList: [],
        counrtyList: [],
        industryType: '',
        industryList: [],
        activeTypeList: [],
        peopleNumList: [],
        squareList: [],
        workAddress: [],
        workType: [],
        workPrice: [],
        meetingType: '',
      })

      const handleChangeUpLoadFile = async (e) => {
        console.log(e)
        if (state.fileList.length >= 3) {
          proxy.$baseMessage(
            `当前限制选择 3 个文件，本次选择了3个文件`,
            'error',
            'vab-hey-message-error'
          )
          return false
        }
        let upData = new FormData()
        upData.append('file', e.file)
        const { data } = await upLoadAttchments(upData)
        data.name = e.file.name
        console.log(data, e.file)
        state.fileList.length == 0
          ? (state.fileList[0] = data)
          : state.fileList.push(data)
      }
      const handleRemoveFile = (e) => {
        let delIndex = state.fileList.indexOf(e.fileUrl)
        if (state.fileList.length == 1) {
          state.fileList = []
        } else {
          state.fileList.splice(delIndex, 1)
        }
      }

      const upBannar = async (e) => {
        if (state.form.banner.length >= 10) {
          proxy.$baseMessage(
            `当前限制选择 10 个文件，本次选择了10个文件`,
            'error',
            'vab-hey-message-error'
          )
          return false
        }
        let upData = new FormData()
        upData.append('file', e.file)
        const { data } = await upLoadAttchments(upData)
        state.form.banner.length == 1 && state.form.banner[0] == ''
          ? (state.form.banner[0] = data)
          : state.form.banner.push(data)
      }

      // const handleError = (err, file) => {
      //   proxy.$baseMessage(
      //     `文件[${file.raw.name}]上传失败,文件大小为${_.round(
      //       file.raw.size / 1024,
      //       0
      //     )}KB`,
      //     'error',
      //     'vab-hey-message-error'
      //   )
      // }

      // const handleExceed = (files) => {
      //   proxy.$baseMessage(
      //     `当前限制选择 11 个文件，本次选择了11个文件`,
      //     'error',
      //     'vab-hey-message-error'
      //   )
      // }

      // const handleRemove = () => {
      //   state.form.coverUrl = ''
      // }
      const handlePreview = (url) => {
        console.log(url)
        state.dialogImageUrl = url
        state.dialogVisible = true
      }

      const submitInfo = () => {
        console.log(state.form)
        console.log(state.status)
        state['formRef'].validate(async (valid) => {
          if (valid) {
            let exhibitorContacts = []
            state.contactInfo.map((res, index) => {
              let obj = {}
              obj.dutyDescription = res.dutyDescription
              obj.uid = res.uid
              obj.sort = index
              obj.type = 'EXHIBIT'
              obj.language = 'CN'
              obj.code = state.form.exhibitorCode
              obj.relationCode =
                state.status == 'new' ? state.notRealCode : state.exhibitCode
              exhibitorContacts.push(obj)
            })
            console.log(exhibitorContacts, 'exhibitorContacts')
            if (exhibitorContacts.length) {
              await addBatchContactInfo(exhibitorContacts)
            }

            if (state.industryType == 'HOST') {
              if (state.form.eventStartTime) {
                state.form.eventStartTime = parseTime(
                  state.form.eventStartTime,
                  '{y}-{m}-{d}'
                )
              }
              if (state.form.eventEndTime) {
                state.form.eventEndTime = parseTime(
                  state.form.eventEndTime,
                  '{y}-{m}-{d}'
                )
              }
              state.form.productType = state.industryType
              state.form.country = state.counrtyList.filter(
                (v) => v.countryCode == state.form.countryCode
              )[0].country
            }
            if (
              state.industryType == 'VENUES' ||
              state.industryType == 'WORK'
            ) {
              state.form.productType = state.industryType
            }

            if (state.status == 'new') {
              state.form.attachmentIds = state.form.banner.map(
                (item) => item.id
              )
              state.form.exhibitCode =
                state.status == 'new' ? state.notRealCode : state.exhibitCode
              state.form.productAttachmentIds = state.fileList.map(
                (item) => item.id
              )
              state.form.details = state.form.detailsToc
              console.log(state.form.categoryIds, 'categoryIds')
              state.form.categoryIds = state.form.categoryIdsStr
                ? [state.form.categoryIdsStr]
                : ''
              let { data, msg } = await addBrandProduct(state.form)
              proxy.$baseMessage(
                `操作成功`,
                'success',
                'vab-hey-message-success'
              )
              proxy.$router.go(-1)
            } else {
              state.form.attachmentIds = state.form.banner.map(
                (item) => item.id
              )
              state.form.productAttachmentIds = state.fileList.map(
                (item) => item.id
              )
              state.form.categoryIds = state.form.categoryIdsStr
                ? [state.form.categoryIdsStr]
                : ''
              state.form.details = state.form.detailsToc
              let { data, msg } = await editBrandProduct(state.form)
              proxy.$baseMessage(
                `操作成功`,
                'success',
                'vab-hey-message-success'
              )

              proxy.$router.go(-1)
            }
          }
        })
      }

      const upLoadCover = () => {
        state['coverCropperRef'].dialogVisible = true
      }
      const upLoadProduct = () => {
        if (state.form.banner.length >= 10) {
          proxy.$baseMessage(
            `当前限制选择 10 个文件，本次选择了10个文件`,
            'error',
            'vab-hey-message-error'
          )
          return false
        }
        state['productCropperRef'].dialogVisible = true
      }

      const coverInfo = (logo) => {
        console.log('logo', logo)
        state.form.cover = logo
      }
      const productInfo = (data) => {
        console.log('data', data)
        state.form.banner.length == 1 && state.form.banner[0] == ''
          ? (state.form.banner[0] = data)
          : state.form.banner.push(data)
      }

      const deleteLogo = () => {
        state.form.logo = ''
      }

      const deleteCover = () => {
        state.form.cover = ''
      }

      const deleteBannar = (url) => {
        let delIndex = state.form.banner.findIndex(
          (item) => item.fileUrl == url
        )
        console.log(url, 'bannar', delIndex)
        if (state.form.banner.length == 1) {
          state.form.banner = ['']
        } else {
          state.form.banner.splice(delIndex, 1)
        }
      }

      // 设置editor 实例
      const handleCreated = (editor) => {
        editorRef.value = editor // 记录 editor 实例，重要！
      }

      const toolbarConfig = {
        excludeKeys: ['fullScreen', 'group-video'],
      }
      const editorConfig = {
        placeholder: '请输入内容...',
        MENU_CONF: {
          uploadImage: {
            async customUpload(res, insertFn) {
              let upData = new FormData()
              upData.append('file', res)
              const { data } = await upLoadImg(upData)
              insertFn(data, res.name)
            },
          },
        },
      }

      onBeforeUnmount(() => {
        const editor = editorRef.value
        if (editor == null) return
        editor.destroy()
      })

      const fetchData = async () => {
        state.status = route.query.id == 'new' ? 'new' : 'edit'
        state.meetingType = route.query.meetingType
        console.log(state.status)
        state.industryType = route.query.industryType
        if (state.status == 'edit') {
          state.exhibitCode = route.query.id
          const { data } = await getBrandProductDetail({
            exhibitCode: state.exhibitCode,
          })
          if (
            data.exhibitExpansionVenuesVo &&
            data.exhibitExpansionVenuesVo.length
          ) {
            data.activityTypeCode = []
            data.peopleScaleCode = []
            data.venuesAreaCode = []
            data.workAddressCode = []
            data.workTypeCode = []
            data.workPriceCode = []
            data.exhibitExpansionVenuesVo.map((res) => {
              console.log(res)
              if (res.type == 'ACTIVITY_TYPE') {
                res.venuesVos.map((item) => {
                  console.log(item.code)
                  data.activityTypeCode.push(item.code)
                })
              } else if (res.type == 'PEOPLE_SCALE') {
                res.venuesVos.map((item) => {
                  console.log(item.code)
                  data.peopleScaleCode.push(item.code)
                })
              } else if (res.type == 'VENUES_AREA') {
                res.venuesVos.map((item) => {
                  console.log(item.code)
                  data.venuesAreaCode.push(item.code)
                })
              } else if (res.type == "WORK_ADDRESS") {
                res.venuesVos.map((item) => {
                  console.log(item.code)
                  data.workAddressCode.push(item.code)
                })
              } else if (res.type == "WORK_TYPE") {
                res.venuesVos.map((item) => {
                  console.log(item.code)
                  data.workTypeCode.push(item.code)
                })
              } else if (res.type == "WORK_PRICE") {
                res.venuesVos.map((item) => {
                  console.log(item.code)
                  data.workPriceCode.push(item.code)
                })
              }
            })
          }
          console.log(data, '===>>>')
          data.banner = data.attachmentVos.filter(
            (v) => v.productType != 'ATTACHMENT'
          )
          state.fileList = data.attachmentVos.filter(
            (v) => v.productType == 'ATTACHMENT'
          )
          state.fileList.map((v) => {
            v.name = v.fileName
          })
          console.log(data, 'datassss')
          data.categoryIdsStr = data.productAttributeVos
            .map((item) => (item.type == 'TYPE' ? item.id : ''))
            .filter((v) => v != '')[0]
          data.attributeIds = data.productAttributeVos
            .map((item) => (item.type == 'ATTRIBUTE' ? item.id : ''))
            .filter((v) => v != '')
          state.form = Object.assign({}, data)

          state.contactInfo = state.form.enterpriseContactVos
        } else {
          const { data } = await generateNotRealCode({ type: 'EXHIBIT' })
          console.log(data)
          state.notRealCode = data
        }

        const { data: attrList } = await queryProductAttribute(state.queryAttr)
        const { data: typeList } = await queryProductAttribute(state.queryType)
        console.log(attrList, typeList, '[=======]')
        state.attrList = attrList.list
        state.typeList = typeList.list

        // 查询所有国家列表
        const { data: country } = await countryQueryInfo()
        console.log(country)
        state.counrtyList = country

        // 查询行业列表

        const { data: industry } = await queryProductIndustry({
          meetingCode: route.query.meetingCode,
        })
        console.log(industry)
        state.industryList = industry

        // 查询展馆分类
        let params = {
          relationCode: `BASE`,
          enterpriseType: 'ENTERPRISES',
          language: 'CN',
        }
        params.type = `ACTIVITY_TYPE`
        const { data: activeTypeList } = await queryExpansionTypeList(params)
        state.activeTypeList = activeTypeList
        params.type = `PEOPLE_SCALE_TYPE`
        const { data: peopleNumList } = await queryExpansionTypeList(params)
        state.peopleNumList = peopleNumList
        params.type = `VENUES_TYPE`
        const { data: squareList } = await queryExpansionTypeList(params)
        state.squareList = squareList
        params.type = `WORK_ADDRESS`
        const { data: workAddress } = await queryExpansionTypeList(params)
        state.workAddress = workAddress
        params.type = `WORK_TYPE`
        const { data: workType } = await queryExpansionTypeList(params)
        state.workType = workType
        params.type = `WORK_PRICE`
        const { data: workPrice } = await queryExpansionTypeList(params)
        state.workPrice = workPrice
      }

      onMounted(() => {
        handleCreated()
        fetchData()
      })

      const goBack = () => {
        proxy.$router.go(-1)
      }

      const addContact = () => {
        state.code =
          state.status == 'new' ? state.notRealCode : state.exhibitCode
        console.log(`添加联系人`, state.code)
        state['editUserRef'].showEdit({ code: state.code })
      }
      const addUserInfo = (userInfo) => {
        state.contactInfo = userInfo
        console.log(state.contactInfo)
      }
      const upDateContactInfoFunc = async (e) => {
        console.log(e)
        try {
          let obj = {}
          obj.dutyDescription = e.dutyDescription
          obj.type = 'EXHIBIT'
          obj.language = 'CN'
          obj.uid = e.uid
          obj.id = e.contactId
          obj.code = state.form.exhibitorCode
          obj.relationCode =
            state.status == 'new' ? state.notRealCode : state.exhibitCode
          const { data } = await updateContactInfo(obj)
        } catch (e) {}
      }
      const deleteUserInfo = (index) => {
        state.contactInfo.splice(index, 1)
      }

      const optionsLevel = {
        lazy: true,
        value: 'name',
        label: 'name',
        async lazyLoad(node, resolve) {
          const { level } = node
          const { code } = node.data
          if (level == 0) {
            const { data } = await getProvinceInfo()
            resolve(data)
          } else if (level == 1) {
            const { data } = await getCityInfo({ code: code })
            resolve(data)
          } else {
            const { data } = await getAreaInfo({ code: code })
            data.forEach((v, i) => {
              v.leaf = level >= 2
            })
            resolve(data)
          }
        },
      }

      // handleError,
      //       handleExceed,
      //       handleRemove,
      return {
        ...toRefs(state),
        upBannar,
        fetchData,
        submitInfo,
        handlePreview,
        coverInfo,
        upLoadProduct,
        productInfo,
        upLoadCover,
        deleteLogo,
        deleteCover,
        deleteBannar,
        editorRef,
        mode: 'default',
        handleCreated,
        toolbarConfig,
        editorConfig,
        goBack,
        addUserInfo,
        addContact,
        upDateContactInfoFunc,
        deleteUserInfo,
        handleChangeUpLoadFile,
        handleRemoveFile,
        optionsLevel,
      }
    },
  })
</script>

<style lang="scss" scoped>
  .upLoadImg {
    display: flex;
    flex-wrap: wrap;
    .draggable {
      display: flex;
      flex-wrap: wrap;
    }
    .image-slot {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 200px;
      height: 200px;
      background: #f5f7fa;
      color: #909399;
      font-size: 30px;
      margin-right: 20px;
    }
    .image-slot .el-icon {
      font-size: 30px;
      color: #909399;
    }
    .hoveBox {
      width: 200px;
      height: 200px;
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-content: center;
    }
  }

  .userInfoBox {
    display: flex;
    justify-content: left;
    background: #67c23a;
    padding: 10px;
    box-sizing: border-box;
    margin-top: 20px;
    .detailInfo {
      margin-left: 20px;
      color: #fff;
      font-size: 16px;
    }
    .inputBox {
      margin-left: 50px;
      margin-top: 20px;
    }
  }
</style>
